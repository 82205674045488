<template>
  <div>
    <!-- <pre>
      dates: {{ dates }}
    </pre>
    sites: {{ sites }}<br />
    partnerViews: {{ partnerViews }} -->

    <v-container v-if="loading" class="text-center">
      <v-progress-linear color="primary" indeterminate></v-progress-linear>
    </v-container>

    <v-card
      v-else-if="
        sitesSeries.length === 0 ||
        ('data' in sitesSeries[0] && sitesSeries[0]['data'].length === 0)
      "
    >
      <v-card-text>{{ noDataText }}</v-card-text>
    </v-card>
    <div v-else>
      <div v-for="(siteSeries, index) in sitesSeries" :key="index">
        <div v-if="sitesSerieIsVisible[index]">
          <v-card class="mt-4">
            <v-card-title> {{ siteSeries.name }} </v-card-title>
            <v-card-subtitle>
              Total CA:
              <span class="text-ca">{{ siteSeries.total_ca }} €</span>
              &nbsp;◊◊◊&nbsp; Total sessions:
              <span class="text-sessions">{{ siteSeries.total_sessions }}</span>
            </v-card-subtitle>
            <vue-apex-charts
              type="line"
              :height="height"
              :options="chartOptions"
              :series="siteSeries.series"
              class="px-6"
            ></vue-apex-charts>
          </v-card>
        </div>
      </div>
      <v-row
        v-if="lastSitesSerieIsVisibleIndex < sitesSeries.length - 1"
        class="mt-5"
      >
        <v-col cols="3">
          Chargement du graph {{ lastSitesSerieIsVisibleIndex + 1 }} /
          {{ sitesSeries.length }}
        </v-col>
        <v-col cols="9">
          <v-progress-linear
            color="primary"
            :value="
              100 * ((lastSitesSerieIsVisibleIndex + 1) / sitesSeries.length)
            "
            class="mt-5"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import axios, { cacheTime } from "@axios";
import debounce from "lodash/debounce";
import { colors } from "@/utils/constants";
import { mdiAlert, mdiEye } from "@mdi/js";
import { formatNumber, prettyNbs } from "@/utils/formatting";
import {
  getDaysArray,
  getMondaysArray,
  getFirstOfMonthsArray,
} from "@/utils/dates";

export default {
  name: "CaAndAudienceBySiteSeparatedChart",
  components: {
    VueApexCharts,
  },
  props: {
    // params: {
    //   type: Object,
    //   default: {
    //     partnerViews: [],
    //     sites: [],
    //     dates: [],
    //     frequency: "daily",
    //   },
    // },
    dates: {
      type: Array,
      default: () => [],
    },
    partnerViews: {
      type: Array,
      default: () => [],
    },
    sites: {
      type: Array,
      default: () => [],
    },
    frequency: {
      type: String,
      default: "daily",
    },
    componentKey: {
      type: Number,
      default: 0,
    },
  },
  created() {
    console.log(
      "CaAndAudienceBySiteSeparatedChart created this.partnerViews",
      this.partnerViews
    );
    if (this.componentKey > 0) this.debouncedGetData();
  },
  data() {
    return {
      icons: {
        mdiAlert,
        mdiEye,
      },
      sitesSeries: [],
      sitesSerieIsVisible: [],
      lastSitesSerieIsVisibleIndex: 0,
      noDataText: null,
      loading: false,
      title: "",
      legend: true,
      colorCA: colors.ca,
      colorSessions: colors.sessions,
      colors: [colors.ca, colors.ca, colors.sessions, colors.sessions],
      stroke: {
        show: true,
        curve: "smooth",
        width: 2,
        dashArray: [], // 0, 2
      },
      height: 300,
      labelsEnabled: false,
      yAxis: [
        {
          title: {
            text: "Revenue",
          },
          seriesName: "Revenue",
          min: 0,
          forceNiceScale: true,
          labels: {
            formatter: (val) => formatNumber(val),
          },
        },
        {
          seriesName: "Revenue",
          min: 0,
          show: false,
          forceNiceScale: true,
          labels: {
            formatter: (val) => formatNumber(val),
          },
        },
        {
          title: {
            text: "Sessions",
          },
          seriesName: "Sessions",
          opposite: true,
          min: 0,
          forceNiceScale: true,
          labels: {
            formatter: (val) => formatNumber(val),
          },
        },
        {
          seriesName: "Sessions",
          show: false,
          min: 0,
          forceNiceScale: true,
          labels: {
            formatter: (val) => formatNumber(val),
          },
        },
      ],
      xAxis: {
        categories: [],
        labels: {
          rotate: -45,
        },
        style: {
          fontSize: "12px",
          fontWeight: 400,
        },
      },
      chartOptions: {
        chart: {
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6,
          },
        },
        grid: {
          borderColor: "#f1f1f1",
        },
      },
    };
  },
  methods: {
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      //try {
      let queryParams = {
        group_ca: true,
        date_start: this.dates[0],
        date_end: this.dates[1],
        period_group_type: this.frequency,
        ...(this.sites && this.sites.length > 0
          ? { sites: this.sites.join(",") }
          : {}),
        ...(this.partnerViews && this.partnerViews.length > 0
          ? { partner_views: this.partnerViews.join(",") }
          : {}),
      };
      this.loading = true;
      const response = await axios.get(
        "/programmatic/ca-audience-objectives-by-site",
        {
          params: queryParams,
          cache: {
            maxAge: cacheTime,
          },
        }
      );
      this.loading = false;
      if (!response.data || response.data.length === 0) {
        console.warn("No data available from the response");
        this.noDataText = "No data available";

        return;
      }
      let sitesData = response.data;
      //console.log("sitesData", sitesData);

      let categories;
      switch (this.frequency) {
        case "weekly":
          categories = getMondaysArray(this.dates[0], this.dates[1]);
          break;
        case "monthly":
          categories = getFirstOfMonthsArray(this.dates[0], this.dates[1]);
          break;
        default: // daily
          categories = getDaysArray(this.dates[0], this.dates[1], true);
          break;
      }

      //console.log("categories", categories);
      // categories = this.addMonthBoundariesToDateList(categories);

      this.fillPeriodData(categories, sitesData);
      //console.log("sitesData 2", sitesData);
      this.fillMonthData(categories, sitesData, this.frequency);

      // categories = categories.map((dateStr) => new Date(dateStr).getTime());
      if (this.frequency == "weekly") {
        categories = categories.map((dateStr) => `Semaine du ${dateStr}`);
      } else if (this.frequency == "monthly") {
        categories = categories.map((dateStr) => dateStr.slice(0, 7));
      }

      const annotations = {
        xaxis: [
          {
            x: "annotations xaxis.0 x",
            borderColor: "#FF0000",
            fillColor: "#FF0000",
            opacity: 0.6,
            label: {
              // borderColor: "#333",
              // style: {
              //   fontSize: "10px",
              //   color: "#333",
              //   background: "#FEB019",
              //   minWidth: "200px",
              //   minHeight: "80px",
              // },
              text: "xaxis.0 text",
            },
          },
        ],
      };

      this.chartOptions = {
        ...this.chartOptions,
        chart: {
          ...this.chartOptions.chart,
          id: this.title,
          height: this.height,
        },
        annotations: annotations,
        legend: {
          show: this.legend,
        },
        colors: this.colors,
        dataLabels: {
          enabled: this.labelsEnabled,
        },
        //stroke: this.stroke,
        stroke: {
          width: [2, 1, 2, 1],
          show: true,
          curve: "smooth", //"straight",
          dashArray: [0, 2, 0, 2],
        },
        xaxis: {
          categories: categories,
          //type: "datetime",
          labels: {
            rotate: -45,
          },
          style: {
            fontSize: "12px",
            fontWeight: 400,
          },
        },
        yaxis: this.yAxis,
      };
      /*
        sitesData = [
          {
            name: "mysite A"
            series: [
              { name: "CA", data: [5, 15, 18, ...] },
              { name: "CA Objectives", data: [21, 21, 21, ...] },
              { name: "Sessions", data: [300, 1100, 1005, ...] },
              { name: "Sessions Objectives", data: [553, 553, 553, ...] }
            ],
          },
          {
            name: "mysite B"
            series: [
              { name: "CA", data: [6, 9, 15, ...] },
              { name: "CA Objectives", data: [12, 12, 12, ...] },
              { name: "Sessions", data: [7865, 6358, 741, ...] },
              { name: "Sessions Objectives", data: [6874, 6874, 6874, ...] }
            ],
          },
          ...
        ];
        */
      // if (true) {
      //   // pendant les tests
      //   this.sitesSeries = this.sitesDataToSitesSeries(sitesData).slice(0, 1);
      //   return;
      // }
      this.sitesSeries = this.sitesDataToSitesSeries(sitesData);
      this.displayGraphsOneByOne();
      // } catch (error) {
      //   console.error("Error loading chart data:", error);
      //   this.noDataText = "Error loading data";
      // }
    },
    async displayGraphsOneByOne() {
      for (let i = 0; i < this.sitesSeries.length; i++) {
        this.sitesSerieIsVisible.push(false); // Initialize all graphs as hidden
      }
      for (let i = 0; i < this.sitesSeries.length; i++) {
        await this.delayGraphDisplay(i); // Introduce a delay before showing each graph
        this.$set(this.sitesSerieIsVisible, i, true); // Display the graph one by one
        this.lastSitesSerieIsVisibleIndex = i;
      }
    },
    delayGraphDisplay(index) {
      return new Promise((resolve) => {
        setTimeout(resolve, 300 * index); // Adjust 300ms for desired delay
      });
    },
    sitesDataToSitesSeries(sitesData) {
      return sitesData.map((site) => {
        return {
          name: site.name,
          total_ca: prettyNbs(Math.round(site.total_ca)),
          total_sessions: prettyNbs(site.total_sessions),
          // total_objective_ca: prettyNbs(
          //   Math.round(
          //     site.monthly_objective_ca_complete_array.reduce(
          //       (acc, current) => acc + current,
          //       0
          //     )
          //   )
          // ),
          // total_objective_sessions: prettyNbs(
          //   Math.round(
          //     site.monthly_objective_sessions_complete_array.reduce(
          //       (acc, current) => acc + current,
          //       0
          //     )
          //   )
          // ),
          series: [
            { name: "CA", data: site.period_ca_complete_array },
            {
              name: "CA Objectives",
              data: site.monthly_objective_ca_complete_array,
            },
            { name: "Sessions", data: site.period_sessions_complete_array },
            {
              name: "Sessions Objectives",
              data: site.monthly_objective_sessions_complete_array,
            },
          ],
        };
      });
    },
    calculateWeeklyAverages(monthlyData) {
      const getDaysInMonth = (year, month) =>
        new Date(year, month + 1, 0).getDate();
      const weeklyAverages = {};
      for (const [date, value] of Object.entries(monthlyData)) {
        const [year, month] = date.split("-").map(Number);
        const daysInMonth = getDaysInMonth(year, month - 1); // Month - 1 because Date months are 0-indexed
        const weeksInMonth = daysInMonth / 7;
        weeklyAverages[`${year}-${String(month).padStart(2, "0")}`] =
          value / weeksInMonth;
      }
      return weeklyAverages;
    },
    fillPeriodData(dateRange, sitesData) {
      // this function is meant to fill match a value for each day of the dateRange or null
      sitesData.forEach((site) => {
        Object.keys(site).forEach((prop) => {
          if (
            prop.includes("period") &&
            typeof site[prop] === "object" &&
            site[prop] !== null &&
            !Array.isArray(site[prop])
          ) {
            const newPropName = `${prop}_complete_array`;
            site[newPropName] = dateRange.map((date) =>
              date in site[prop] ? site[prop][date] : null
            );
          }
        });
      });
    },
    fillMonthData(dateRange, sitesData, periodType) {
      // this function is meant to fill match a value for each day of the dateRange or null
      sitesData.forEach((site) => {
        Object.keys(site).forEach((prop) => {
          if (
            prop.includes("monthly") &&
            typeof site[prop] === "object" &&
            site[prop] !== null &&
            !Array.isArray(site[prop])
          ) {
            const newPropName = `${prop}_complete_array`;
            //console.log(`site ${site} ${prop} site[prop]`, site[prop]);

            let monthlyData = site[prop];
            //console.log(`site ${site.name} ${prop} monthlyData 1`, monthlyData);
            if (periodType == "daily" || periodType == "monthly") {
              monthlyData = Object.fromEntries(
                Object.entries(monthlyData).map(([key, value]) => [
                  key.slice(0, 7),
                  value,
                ])
              );
            } else if (periodType == "weekly") {
              monthlyData = this.calculateWeeklyAverages(monthlyData);
            }

            // console.log(`site ${site.name} ${prop} monthlyData 2`, monthlyData);
            // console.log("dateRange", dateRange);

            site[newPropName] = dateRange.map((date) => {
              const month = date.slice(0, 7); // Extract 'YYYY-MM' from date
              if (month in monthlyData) {
                let periodValue = null;
                if (periodType == "daily" || periodType == "monthly") {
                  // Calculate the number of days in the month
                  const [year, monthNum] = month.split("-").map(Number);
                  const daysInMonth = new Date(year, monthNum, 0).getDate();
                  // Divide the monthly value by the number of days
                  periodValue = monthlyData[month] / daysInMonth;
                } else {
                  periodValue = monthlyData[month];
                }
                return Math.round(periodValue);
              } else {
                return null;
              }
            });
          }
        });
      });
    },
  },
  watch: {
    // params: function () {
    //   if (!(this.dates && this.dates.length === 2)) {
    //     console.warn("CaAndAudienceBySiteSeparatedChart missing param dates");
    //     return;
    //   }
    //   if (!(this.partnerViews && this.partnerViews.length > 0)) {
    //     console.warn(
    //       "CaAndAudienceBySiteSeparatedChart missing param partnerViews"
    //     );
    //     return;
    //   }
    //   if (!(this.sites && this.sites.length > 0)) {
    //     console.warn("CaAndAudienceBySiteSeparatedChart missing param sites");
    //     return;
    //   }
    //   //console.log("-- get data");
    //   this.debouncedGetData();
    // },
    dates: function () {
      if (!(this.dates && this.dates.length === 2)) {
        console.warn(
          "CaAndAudienceBySiteSeparatedChart missing prop dates",
          this.dates
        );
        return;
      }
      this.debouncedGetData();
    },
    partnerViews: function () {
      if (!(this.partnerViews && this.partnerViews.length > 0)) {
        console.warn(
          "CaAndAudienceBySiteSeparatedChart missing prop partnerViews",
          this.partnerViews
        );
        return;
      }
    },
    sites: function () {
      if (!(this.sites && this.sites.length > 0)) {
        console.warn(
          "CaAndAudienceBySiteSeparatedChart missing prop sites",
          this.sites
        );
        return;
      }
      this.debouncedGetData();
    },
  },
};
</script>
<style>
.text-ca {
  color: #feb019;
}
.text-sessions {
  color: #008ffb;
}
</style>
